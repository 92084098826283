<template>
  <div class="attest">
    <nav-bar title="购物车" :fixed="true" :placeholder="true" :safe-area-inset-top="true" left-arrow
      @click-left="onClickLeft" />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="listItem" v-if="listItem.length">
        <swipe-cell class="item" v-for="(item, index) in listItem" :key="index">
          <div class="itemCont">
            <div class="checkBoxWh">
              <checkbox v-model="item.isShow" @click.stop="singleChecked(item, index)"></checkbox>
            </div>
            <div @click="toPathPage(`yqMallDetail?id=${item.spu_id}`)" class="itemImg">
              <img :src="item.sku_info.image" alt="" />
            </div>
            <div class="cont">
              <div @click="toPathPage(`yqMallDetail?id=${item.spu_id}`)" class="contName">
                {{ item.spu_info.title }}
              </div>
              <div class="contCheckName" @click.stop="goodsSku(item, index)">
                {{ item.sku_info.name }}
              </div>
              <div class="contMoney">
                <div class="money">
                  <span class="itemPrice" v-if="item.sku_info.price.price != '0.00'">￥{{ item.sku_info.price.price
                  }}</span>
                  <span class="addIcon" v-if="item.sku_info.price.price != '0.00' &&
                    item.sku_info.price.score != '0'
                    ">
                    +
                  </span>
                  <span class="itemScore" v-if="item.sku_info.price.score != '0'">{{ item.sku_info.price.score }}
                    <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                  </span>
                </div>
                <div class="num">x{{ item.num }}</div>
              </div>
            </div>
          </div>
          <!-- <cell :border="false" title="单元格" value="内容" /> -->
          <template #right>
            <van-button square @click="deleteShopping(item, index)" type="danger" text="删除" />
          </template>
        </swipe-cell>
      </div>
      <div class="noPage" v-else>
        <div class="noList">
          <div class="noListText">暂无商品，快去挑选吧</div>
          <div class="noListBtn" @click="goTo('yqMallTabs')">前往元气当铺</div>
        </div>
      </div>

    </van-pull-refresh>

    <div class="btm">
      <div class="btm_t">
        <checkbox v-model="checked" @click="checkAll()"></checkbox>
        <div class="btm_t_text">全选（{{ selectedNum }}）</div>
      </div>
      <div class="btm_b">
        <!-- <div class="van-sku-row__item-name">
          <span v-if="moneyCont != '0.00'">￥{{ moneyCont }}</span>
          <span v-if="moneyCont != '0.00' && moneyScore != '0'"> + </span>
          <span v-if="moneyScore != '0'">{{ moneyScore }}
            <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
          </span>
        </div> -->
        <div class="btm_b_text">
          <div class="text_money">
            <span class="span1">合计:</span>
            <span class="span2">￥{{ moneyCont }}</span>
            <span class="span2"> + </span>
            <span class="itemScore span2">{{ moneyScore }}
              <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
            </span>
          </div>
        </div>

        <!-- <div class="btm_b_text">
          <div class="text_money">
            <span class="span1">合计：</span>
            <span class="span2">￥{{ moneyCont }}</span>
          </div>
          <div class="text_yq">
            <span class="span1">所需元气</span>
            <span class="span2">{{ moneyScore }}</span>
          </div>
        </div> -->
        <div class="btm_b_btn" @click="onGotoPay('yqMallConfirmOrder')">
          去结算
        </div>
      </div>
    </div>
    <van-popup v-model="show" round closeable :safe-area-inset-bottom="true" close-icon="close" position="bottom"
      @close="popupClose()">
      <div class="van-sku-header van-hairline--bottom">
        <div class="van-image van-sku-header__img-wrap">
          <img :src="skuSelectData.image" class="van-image__img" style="object-fit: cover" />
        </div>
        <div class="van-sku-header__goods-info">
          <div class="sku_goods_name">
            <span class="">{{ skuSelectData.name }}</span>
          </div>
          <div class="sku_goods_price">
            <!-- <span class="sku_price_symbol">￥</span
            > -->
            <div class="sku_price_num" v-if="JSON.stringify(priceData) != '{}'">
              <span v-if="priceData.price != '0.00'">￥{{ priceData.price }}</span>
              <span v-if="priceData.price != '0.00' && priceData.score != '0'">
                +
              </span>
              <span v-if="priceData.score != '0'">{{ priceData.score }}
                <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
              </span>
            </div>
          </div>
          <div class="van-sku-header-item">
            <span class="van-sku__stock">剩余
              <span class="van-sku__stock-num">{{ skuSelectData.stock }}</span>
              件</span>
          </div>
          <div class="sku_header_item">
            已选
            {{ skuSelectData.value }}
          </div>
        </div>
      </div>
      <div class="van-sku-body" style="max-height: 300px">
        <div class="van-sku-group-container">
          <div class="van-sku-row van-hairline--bottom" v-for="(ProductItem, n) in simulatedDATA.specifications" :key="n">
            <div class="van-sku-row__title">{{ ProductItem.title }}</div>
            <span @click="specificationBtn(oItem.name, n, $event, index)" :class="[
              subIndex[n] == index ? 'van-sku-row__item--active' : '',
              oItem.isShow ? '' : 'disabled',
              'van-sku-row__item',
            ]" v-for="(oItem, index) in ProductItem.options" :key="index">
              <div class="van-sku-row__item-name">{{ oItem.name }}</div>
            </span>
          </div>
        </div>
        <div class="van-sku-stepper-stock">
          <div class="van-sku__stepper-title">购买数量</div>
          <div class="van-stepper">
            <van-stepper :max="skuSelectData.stock" v-model="value" />
          </div>
        </div>
        <div class="van-sku-group-container" v-if="skuSelectData.price">
          <div class="van-sku-row van-hairline--bottom" v-if="skuSelectData.price.length">
            <div class="van-sku-row__title">支付方式</div>
            <span v-for="(i, n) in skuSelectData.price" :key="n">
              <div :class="[
                i.isShow ? 'van-sku-row__item--active' : '',
                'van-sku-row__item',
              ]" @click="priceType(i, n)">
                <div class="van-sku-row__item-name">
                  <span v-if="i.price != '0.00'">￥{{ i.price }}</span>
                  <span v-if="i.price != '0.00' && i.score != '0'"> + </span>
                  <span v-if="i.score != '0'">{{ i.score }}
                    <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                  </span>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="van-sku-actions">
        <button @click="onPay()" class="van-button van-button--danger van-button--large">
          <div class="van-button__content">
            <span class="van-button__text">确定</span>
          </div>
        </button>
      </div>
    </van-popup>
    <van-dialog v-model="showDialog" width="270" title="确认要删除该商品吗？" cancel-button-color="#a9a9a9"
      confirm-button-color="#000000" @confirm="onDeleteShopping(deleteData.e, deleteData.i)"
      show-cancel-button></van-dialog>
  </div>
</template>

<script>
import { get_sku, cart_list, upd_sku, del_cart } from "../../api/yqMallApi";
import { SwipeCell, Cell, Checkbox, NavBar, CheckboxGroup } from "vant";
import { Sku } from "vant";
export default {
  name: "Attest",
  components: { SwipeCell, Cell, Checkbox, NavBar, Sku, CheckboxGroup },
  data() {
    return {
      showDialog: false,
      listItem: [],
      checked: false,//全选反选
      show: false,
      result: [],
      selectedNum: 0,
      isLoading: false,
      selectIndex: 0,
      skuSelectData: {}, //选择的sku
      simulatedDATA: {
        //模拟后台返回的数据 多规格
        difference: [],
        specifications: [],
      },
      value: 0,
      selectArr: [], //存放被选中的值
      shopItemInfo: {}, //存放要和选中的值进行匹配的数据
      subIndex: [], //是否选中 因为不确定是多规格还是单规格，所以这里定义数组来判断
      skuData: {}, //sku接口数据
      priceData: {}, //sku选择支付方式数据
      buttonType: 3, //1 购物车  2 购买 确定
      page: 1,
      deleteData: {
        e: {},
        i: ''
      }

    };
  },
  mounted() {
    console.log(localStorage.getItem('addressData'))
    this.onGetList();
    this.handleScroll();
  },
  computed: {
    //计算总价，选择被选中的元素
    moneyCont() {
      if (this.listItem.length) {
        return this.listItem
          .filter((item) => item.isShow)
          .reduce((pre, cur) => {
            return pre + cur.sku_info.price.price * cur.num;
          }, 0);
      }

    },
    //计算总元气价格，选择被选中的元素
    moneyScore() {
      if (this.listItem.length) {
        return this.listItem
          .filter((item) => item.isShow)
          .reduce((pre, cur) => {
            return pre + cur.sku_info.price.score * cur.num;
          }, 0);
      }

    },
  },
  created() { },
  methods: {
    //上划加载
    handleScroll() {
      let _this = this;
      window.onscroll = function () {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (Math.ceil(scrollTop + windowHeight) == scrollHeight) {
          console.log(12244)
          _this.page++
          _this.onGetList();
        }
      };
    },
    onRefresh() {
      this.onInitialization();
      setTimeout(() => {
        this.isLoading = false
      }, 2000)
    },
    //关闭弹框 则数据清空 恢复默认值
    popupClose() {
      setTimeout(() => {
        this.priceData = {};
        this.skuSelectData = {};
        this.simulatedDATA.specifications = [];
        this.simulatedDATA.difference = [];
        this.subIndex = [];
        this.selectArr = [];
        this.$forceUpdate();
      }, 250);
    },
    //删除商品弹框
    async deleteShopping(e, i) {
      this.deleteData.e = e;
      this.deleteData.i = i
      this.showDialog = true
    },
    //操作完后初始化
    onInitialization() {
      this.listItem = []
      this.onGetList();
      this.checked = false;
      this.selectedNum = 0;
    },
    //删除商品
    async onDeleteShopping(e, i) {
      try {
        const res = await del_cart({
          id: this.listItem[i].id,
        });
        this.onInitialization();
        this.$toast("删除成功");
      } catch (error) {
        this.$toast("删除失败，请稍后重试~");
      }
    },
    //支付方式选择
    priceType(item, index) {
      this.priceData = item;
      for (let i = 0; i < this.skuSelectData.price.length; i++) {
        this.skuSelectData.price[i].isShow = false;
      }
      this.skuSelectData.price[index].isShow = true;
      this.$forceUpdate();
    },
    onShopping(e) {
    },
    goTo(name) {
      this.$router.push({ name });
    },
    onGotoPay(name) {
      let arr = [];
      for (let s = 0; s < this.listItem.length; s++) {
        if (this.listItem[s].isShow) {
          arr.push(this.listItem[s].id);
        }
      }
      if (arr.length) {
        // let data = {};
        this.$router.push({
          name,
          query: { data: JSON.stringify(arr), payType: "shopping", pageName: 'shopping' },
        });
        // this.$router.push({ name });
      } else {
        this.$toast.fail("请选择一件商品");
      }
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    //获取某一条数据
    singleChecked(e, i) {
      // item.isShow
      this.checked = this.listItem.every((item) => item.isShow);
      // let _index=this.listItem.findIndex(item=>{
      //   return !item.isShow
      // })
      // _index == -1 ? this.checked=true : this.checked=false
      this.selectedNum = 0;
      for (let s = 0; s < this.listItem.length; s++) {
        if (this.listItem[s].isShow) {
          this.selectedNum++;
        }
      }
      console.log(99, e, i);
    },
    //全选 反选
    checkAll() {
      for (let i = 0; i < this.listItem.length; i++) {
        this.listItem[i].isShow = this.checked;
      }
      if (!this.checked) {
        this.selectedNum = 0;
      } else {
        this.selectedNum = this.listItem.length;
      }
    },
    //获取购物车列表
    async onGetList() {
      try {
        const res = await cart_list({
          page: this.page
        });
        console.log(1112, res.data.data.data)
        let data = res.data.data.data;
        for (let i = 0; i < data.length; i++) {
          this.listItem.push({
            ...data[i],
            isShow: false
          });
        }
        console.log(55, this.listItem);
        this.$forceUpdate();
      } catch (e) {
        this.$toast.fail("加入购物车失败");
        console.log(e);
      }
    },

    //商品sku详情选择
    async goodsSku(e, s) {
      this.selectIndex = s;
      try {
        const res = await get_sku({
          spu_id: e.spu_id,
        });
        this.skuData = res.data.data;
        this.simulatedDATA.specifications = this.skuData.options;
        this.simulatedDATA.difference = this.skuData.sku;
        this.skuData.sku.forEach((r, i) => {
          if (r.id == e.sku_id) {
            this.onPriceAddIsShow(this.skuData.sku[i]);
          }
        });
        for (let i = 0; i < this.simulatedDATA.difference.length; i++) {
          this.simulatedDATA.difference[i].value =
            this.simulatedDATA.difference[i].value.join();
        }
        var self = this;
        for (var i in self.simulatedDATA.difference) {
          self.shopItemInfo[self.simulatedDATA.difference[i].value] =
            self.simulatedDATA.difference[i]; //修改数据结构格式，改成键值对的方式，以方便和选中之后的值进行匹配
        }
        // await self.checkItem();
        this.show = true;
      } catch (e) {
        console.log(e);
      }
    },
    specificationBtn: function (item, n, event, index) {
      console.log('点击', item, n, index)
      var self = this;
      if (self.selectArr[n] != item) {
        self.selectArr[n] = item;
        self.subIndex[n] = index;
      } else {
        self.selectArr[n] = "";
        self.subIndex[n] = -1; //去掉选中的颜色
      }
      self.priceData = {};
      self.checkItem();
      self.onInitialization();
    },
    checkItem: function () {
      var self = this;
      var option = self.simulatedDATA.specifications;
      var result = []; //定义数组储存被选中的值
      for (var i in option) {
        result[i] = self.selectArr[i] ? self.selectArr[i] : "";
      }
      for (var i in option) {
        var last = result[i]; //把选中的值存放到字符串last去
        for (var k in option[i].options) {
          result[i] = option[i].options[k].name; //赋值，存在直接覆盖，不存在往里面添加name值

          option[i].options[k].isShow = self.isMay(result); //在数据里面添加字段isShow来判断是否可以选择
        }
        result[i] = last; //还原，目的是记录点下去那个值，避免下一次执行循环时被覆盖
      }
      if (this.shopItemInfo[result]) {
        this.onPriceAddIsShow(this.shopItemInfo[result]);
      }
      self.$forceUpdate(); //重绘
    },
    isMay: function (result) {
      for (var i in result) {
        if (result[i] == "") {
          return true; //如果数组里有为空的值，那直接返回true
        }
      }
      return this.shopItemInfo[result].stock == 0 ? false : true; //匹配选中的数据的库存，若不为空返回true反之返回false
    },
    onPriceAddIsShow(d) {
      let data = d;
      for (let i = 0; i < data.price.length; i++) {
        data.price[i].isShow = false;
      }
      this.skuSelectData = data;
      this.$forceUpdate();
    },
    //修改SKU信息
    async onPay() {
      if (JSON.stringify(this.skuSelectData) == "{}") {
        this.$toast.fail("请选择类型");
        return false;
      }
      if (!this.skuSelectData.price.length) {
        this.$toast.fail("暂无支付方式~");
        return false
      }
      if (JSON.stringify(this.priceData) == "{}") {
        this.$toast.fail("请选择支付类型");
        return false;
      }
      try {
        console.log(this.skuSelectData);
        const res = await upd_sku({
          id: this.listItem[this.selectIndex].id,
          sku_id: this.skuSelectData.id,
          num: this.value,
          price_id: this.priceData.id,
          spu_id: this.skuSelectData.spu_id

        });
        this.show = false;
        this.checked = false;
        this.onInitialization();
      } catch (error) {
        this.show = false;
      }
    },
    toPathPage(name) {
      this.$router.push({ path: name });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.van-dialog__header {
  padding: 30px 0 !important;
  font-weight: 700;
}

/deep/.van-pull-refresh {
  height: 100%;
}

/deep/.van-loading {
  text-align: center;
}

/deep/.van-pull-refresh__head {
  display: flex;
  justify-content: center;
}

/deep/.van-button--danger {
  height: 100%;
}

/deep/.van-sku-header {
  padding-top: 40px;
}

/deep/.van-popup__close-icon {
  display: none;
}

/deep/.van-sku-header__goods-info {
  justify-content: space-between !important;
}

/deep/.van-checkbox__icon--checked .van-icon {
  background-color: #000000;
  border-color: #000000;
}

/deep/.van-nav-bar .van-icon {
  color: #000000;
  font-size: 20px;

}

/deep/.van-sku-actions .van-button--danger {
  background: #000;
  border-radius: 50px !important;
  padding: 21px 0;
}

/deep/.van-sku-actions .van-button {
  font-size: 18px !important;
}

/deep/.van-sku-row__item {
  background: #cdcdcd;
  color: #555555;
  padding: 0px 15px;
  border-radius: 23px;
}

/deep/ .van-sku-row__item--active {
  background: #535353;
  color: #fff;
  padding: 0px 15px;
  border-radius: 23px;
}

/deep/.van-sku-header__img-wrap {
  width: 73px !important;
  height: 73px !important;
}

.van-sku-row__item-name {
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;

    .moneyYq {
      width: 18px;
      height: 18px;
    }
  }
}

.listItem {
  padding-bottom: 200px;
}

.van-sku-stepper-stock {
  display: flex;
  justify-content: space-between;
}

.noPage {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  position: relative;

  .noList {
    text-align: center;
    // margin-top: 150px;
    text-align: center;
    position: absolute;
    top: 100px;

    .noListText {
      font-size: 16px;
      color: #cdcdcd;
      margin-bottom: 20px;
      text-align: center;
    }

    .noListBtn {
      border-radius: 50px;
      border: 1px solid #000;
      color: #000;
      font-size: 14px;
      padding: 10px 20px;
      display: inline-block;
    }
  }
}



.sku_goods_name {
  span {
    font-size: 16px;
  }
}

.sku_goods_price {
  font-size: 17px;

  .sku_price_num {
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
    }
  }

  img {
    width: 20px;
    height: 20px;
  }
}

.sku_header_item {
  color: #cdcdcd;
  font-size: 12px;
}

.btm {
  position: fixed;
  bottom: 0;
  width: 100%;

  .btm_t {
    width: 100%;
    padding: 15px 20px;
    background: #ededed;
    display: flex;
    align-items: center;

    .btm_t_text {
      font-size: 13px;
      margin-left: 10px;
    }
  }

  .btm_b {
    padding: 20px 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .btm_b_text {
      .text_money {
        // margin-bottom: 13px;
        display: flex;
        align-items: center;
        width: 170px;
        flex-wrap: wrap;

        span {
          display: flex;
          align-items: center;
        }

        .span1 {
          color: #5EB0F5;
          font-size: 17px;
        }

        .span2 {
          color: #5EB0F5;
          font-size: 15px;
          margin: 0 2px 0;
        }

        .itemScore {
          display: flex;
          align-items: center;
          margin-top: 2px;

          .moneyYq {
            width: 25px;
            height: 25px;
          }
        }
      }

      .text_yq {
        .span1 {
          font-size: 12px;
          color: rgb(184, 184, 184);
        }

        .span2 {
          color: #82c6ff;
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }

    .btm_b_btn {
      padding: 15px 50px;
      font-size: 16px;
      color: #ffffff;
      border-radius: 40px;
      background: #000000;
    }
  }
}

.item {
  .itemCont {
    display: flex;
    align-items: center;
    padding: 10px 20px 10px;
    .checkBoxWh{
      width: 26px;
      height: 26px;
    }
    .itemImg {
      margin: 0 20px;
      overflow: hidden;
      border-radius: 6px;

      img {
        width: 80px;
        height: 80px;
      }
    }

    .cont {
      width: 60%;

      .contName {
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }

      .contCheckName {
        background: #ededed;
        color: #565656;
        padding: 4px 15px;
        text-align: center;
        border-radius: 20px;
        margin: 8px 0;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        max-width: 110px;
      }

      .contMoney {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .money {
          display: flex;
          align-items: center;
          font-size: 13px;

          .addIcon {
            margin: 0 4px;
          }

          .itemScore {
            display: flex;
            align-items: center;
          }

          .moneyYq {
            width: 25px;
            height: 25px;
          }
        }

        .num {
          font-size: 15px;
        }
      }
    }
  }
}

.attest {
  position: relative;
  background-size: 100% 100%;
  min-height: 100vh;

  .box {
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 70px 40px;
    background-size: 100% 100%;

    /deep/ .van-cell {
      padding: 0;
      background-color: transparent;
      margin-bottom: 25px;
      align-items: center;

      &:after {
        display: none;
      }

      .van-field__label,
      .van-field__control {
        color: black;
      }

      .van-field__control {
        color: black;
        font-weight: 500;
      }

      .van-field__value {
        background: #ffffff;
        border-radius: 18px;
        padding: 5px 15px;
      }
    }
  }

  .btn {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #ffffff;
    box-shadow: 1px 8px 32px 1px rgba(218, 230, 250, 0.65);
    color: black;
    font-size: 15px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .block {
      width: 80%;
      padding: 35px 20px 20px;
      background: #ffffff;
      box-shadow: 1px 8px 32px 1px rgba(218, 230, 250, 0.65);
      border-radius: 10px;

      .btn-group {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;

        .cancel {
          width: 104px;
          height: 38px;
          border: 1px solid #000000;
          border-radius: 19px;
          line-height: 38px;
          text-align: center;
        }

        .confirm {
          width: 104px;
          height: 38px;
          background: #000000;
          border: 1px solid #000000;
          border-radius: 19px;
          line-height: 38px;
          color: white;
          text-align: center;
        }
      }
    }
  }
}</style>
