
<template>
  <div class="pageFullScreen">
    <!-- 内容 -->
    <!-- :autoplay="3000" -->
    <div class="top">
      <div class="topIcon">
        <img @click="showShare = true" :src="require('../../assets/img/yqMall/fx.png')" alt="">
        <img @click="toPage('yqMallSet')" :src="require('../../assets/img/yqMall/sz.png')" alt="">
        <!-- <van-icon size="20" color="#ffffff" @click="showShare = true" name="share-o" /> -->
        <!-- <van-icon size="20" color="#ffffff" @click="toPage('yqMallSet')" name="setting-o" /> -->
      </div>
      <van-swipe class="my-swipe_1" indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannerList.indexBannerList" @click="toPathPage(item.link)" :key="index">
          <img class="swipeBan_1" :src="item.image" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- @click="toPage('yqMallSearch')" -->
    <div class="headerTitle">
      <div class="headerTitle_1">古法中医 真明火</div>
      <div class="headerTitle_2">寒气湿气灸出来</div>
    </div>

    <!-- <div class="headerTitle" @click="toPage('yqMallTabs')">
      <div class="headerTitle_1">古法中医 真明火</div>
      <div class="headerTitle_2">寒气湿气灸出来</div>
      </div> -->

    <div>
      <van-swipe class="my-swipe_2" :show-indicators="false" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannerList.indexTwoBannerList" @click="toPathPage(item.link)"
          :key="index">
          <div class="swiperBan_2">
            <img class="ban" :src="item.image" alt="" />
            <!-- <div class="swiperBtm">
              <div class="swiperBtmFont">
                <div class="swiperBtmText">
                  <div class="swiperBtmText_1">轻松小镇元气艾灸仪</div>
                  <div class="swiperBtmText_2">数字孪生，采集元气</div>
                </div>
                <div class="swiperBtmRightImg">
                  <img src="" alt="" />
                </div>
              </div>
              <div class="swiperBtmBgk">
                <div class="bgk"></div>
              </div>
            </div> -->
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div v-for="(item, index) in bannerList.shopIndexTypeList" :key="index">
      <div class="swiperBan_3">
        <div class="banHeader">
          <div class="header_text1">YUANQI Goods</div>
          <div class="header_text2">{{ item.title }}</div>
        </div>
        <van-swipe class="my-swipe_3" :autoplay="3000" :show-indicators="false" indicator-color="white">
          <van-swipe-item @click="toPathPage(item.link, 1)">
            <img class="swipeBan_3" :src="item.image" alt="" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="swiper-container" v-if="swiperShowTypeO" id="swiper1">
        <!-- 轮播图 -->
        <swiper :options="swiperOption">
          <swiper-slide class="" v-for="(bannerList, key) in item.banner" :key="key" style="height: 40%">
            <!-- <div @click="toPathPage(bannerList.link)"> -->
            <div @click.stop="toPathPage(bannerList.link)" class="swiper-slides"
              :style="`background:url(${bannerList.image}) no-repeat;background-size: cover;`">
              <!-- <img class="swiper-slides" :src="item.img" alt="" /> -->
            </div>
            <div class="swiperBanText" @click.stop="toPathPage(bannerList.link)">
              <div class="textName">{{ bannerList.title }}</div>
              <div class="textNumber">
                <div class="money">{{ bannerList.text }}</div>
                <div
                  v-if="bannerList.text.indexOf('+') != -1 || (bannerList.text.indexOf('￥') == -1 && bannerList.text.indexOf('+') == -1)"
                  class="textImg" :style="`background:url(${yqImg}) no-repeat;background-size: cover;`">
                </div>
              </div>
            </div>
            <!-- </div> -->

          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <!-- <button @click="postMsg">发送</button> -->
      </div>
    </div>
    <btm-tabbar></btm-tabbar>
    <van-share-sheet v-model="showShare" title="立即分享给好友" :options="options" @select="onSelect" />
  </div>
</template>
<script>
import { bannerList, shopIndexType, indexJianbianList } from "../../api/yqMallApi";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import btmTabbar from "./components/btmTabbar.vue";
import { getWxConfig } from '../../../tool/yqMall'

export default {
  components: { SwiperSlide, Swiper, btmTabbar },
  data() {
    return {
      options: [
        { name: '复制链接', icon: 'link' },
      ],
      showShare: false,
      imagesData: [],
      yqImg: require("../../assets/img/yqMall/yq.png"),
      swiperOption: {
        // autoplay: {
        //   delay: 2000,
        //   disableOnInteraction: false,
        // },
        loop: true, // 无限循环
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: '0%',
        loopAdditionalSlides: 1000,
        slideToClickedSlide: true,// 点击的slide会居中
        pagination: {
          el: ".swiper-pagination",
        },
        loop: true,
        touchRatio: 2,
      },
      bannerList: {
        //banner
        indexBannerList: [], //首页banner
        indexTwoBannerList: [], //首页第二个banner
        shopIndexTypeList: [], //首页分类
      },
      swiperShowTypeO: false //是否展示多商品banner
    };
  },
  // created activated
  created() {
    // getWxConfig('轻松小镇', '元气当铺正式上线！')
    console.log("created");
  },
  // 监听变量
  watch: {},
  // 在使用到的页面 添加如下代码
  mounted() {
    // localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjI4NTE2MiIsImlzcyI6IjI4NTE2MiJ9.mImmdcrEtI092ha4iuASb2SBVwoxJ0gYgcoUYri2iUo')
    if (this.$route.query.token) {
      localStorage.setItem('token', this.$route.query.token)
    }
    this.onBanner();
    this.onShopIndexType();
    this.onTwoBanner();
    setTimeout(() => {
      this.swiperShowTypeO = true
    }, 200)
    // this.onGetAll();
  },

  // 页面离开 destroyed deactivated
  destroyed() {
    console.log("destroyed");
  },
  methods: {
    //分享
    onSelect(option) {
      if (option.icon == 'link') {
        var input = document.createElement("input"); // 创建input对象
        input.value = window.location.href; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        this.$toast('复制成功');
      }
      this.showShare = false;
    },
    async onTwoBanner() {
      try {
        const res = await indexJianbianList({});
        this.bannerList.indexTwoBannerList = res.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    async onBanner() {
      try {
        const res = await bannerList({});
        this.bannerList.indexBannerList = res.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    //首页分类banner
    async onShopIndexType() {
      try {
        const res = await shopIndexType({});
        this.bannerList.shopIndexTypeList = res.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    toPage(name) {
      this.$router.push({ name });
    },
    //获取浏览器地址参数
    GetQueryString(url, name) {
      var urls = url, code = '';
      if (urls.indexOf("?") != -1) {    //是否有参数
        var str = urls.substr(1);
        let strs = str.split("=");
        code = strs[1];
      }
      return code
    },
    async toPathPage(name, type) {
      //链接跳转 若包含http跳转外链  反正则跳转后台对应APP页面 
      if (name.indexOf('http') != -1) {
        window.location.href = name
      } else {
        //将筛选页面的ID存入sessionStorage
        if (type == 1) {
          let tabsId = await this.GetQueryString(name);
          sessionStorage.setItem('tabsId', tabsId)
        }
        this.$router.push({ path: name });
      }
    },
  },
};
</script>
<style scoped>
.pageFullScreen {
  /* height: 2000px; */
  margin-bottom: 100px;
}
</style>
<style  scoped lang="scss">
$ban_Width: 92% !important;
$ban_height: 200px;

.top {
  position: relative;

  .topIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9;
    display: flex;
    align-items: center;

    i {
      margin-left: 20px;
    }
    img{
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
  }
}

.swiperBan_2 {
  position: relative;
  height: 100%;

  .ban {
    width: 100%;
    height: 100%;
  }

  .swiperBtm {
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    height: 30%;
    position: absolute;
    bottom: 0;

    .swiperBtmFont {
      position: absolute;
      z-index: 1;
      padding: 7px 20px;

      .swiperBtmText {
        .swiperBtmText_1 {
          font-size: 16px;
          font-weight: 700;
        }

        .swiperBtmText_2 {
          font-size: 13px;
        }
      }

      .swiperBtmRightImg {}
    }

    .swiperBtmBgk {
      position: relative;
      width: 100%;
      height: 100%;
      opacity: 0.4;

      .bgk {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #4e9bff;
      }
    }

  }
}

.headerTitle {
  margin: 20px 0 50px;

  .headerTitle_1 {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }

  .headerTitle_2 {
    font-size: 12px;
    text-align: center;
  }
}

.my-swipe_1 .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  height: 500px;
}

/deep/ .van-swipe__indicator--active {
  border-radius: 10px;
  width: 15px;
}

.swipeBan_1 {
  width: 100%;
  height: 100%;
}

.swipeBan_3 {
  width: 100%;
  height: 100%;
}

.my-swipe_2 {
  color: #fff;
  font-size: 20px;
  text-align: center;
  height: $ban_height;
  width: $ban_Width;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
}

.swiperBan_3 {
  margin-top: 50px;

  .banHeader {
    width: $ban_Width;
    margin: 0 auto 20px;

    .header_text1 {
      font-size: 17px;
    }

    .header_text2 {
      font-size: 17px;
    }
  }

  .my-swipe_3 {
    color: #fff;
    font-size: 20px;
    text-align: center;
    height: $ban_height;
    width: $ban_Width;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
  }
}

/* pageFullScreen在index.css */
#swiper1 {
  width: 100%;
  overflow: hidden;
  padding: 0 0 10px 0;
}

.swiper-container,
.swiper-container2 {
  width: 100%;
  height: 310px;
  // overflow: visible !important;
  position: relative;
  padding-top: 30px;
}

#swiper1 .swiper-container .swiper-wrapper .swiper-slide {
  width: 165px;
  // width: 49%;
  // height: 100%;
  border-radius: 10px;
  //
  transition: 300ms;
  transform: scale(0.8);
}

#swiper1 .swiper-container .swiper-wrapper .swiper-slide .swiperBanText {
  .textName {
    text-align: center;
    font-size: 17px;
    margin: 0 auto 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    font-weight: inherit;
    width: 80%;
  }

  .textNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;

    .money {
      font-size: 18px !important;

    }

    .textImg {
      // margin-left: 10px;
      width: 25px;
      height: 25px;
    }
  }
}

#swiper1 .swiper-container .swiper-wrapper .swiper-slide .swiper-slides {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: 300ms;
  overflow: hidden;
  background-size: cover;
  // img {
  //   width: 100%;
  //   height: 100%;
  // }
}

/* 上一张 */
#swiper1 {}

.swiperBanText {
  margin-top: 20px;
}

#swiper1 .swiper-container .swiper-wrapper .swiper-slide-prev {
  margin-top: 5px;
  height: 155px !important;
}

/* 下一张 */
#swiper1 .swiper-container .swiper-wrapper .swiper-slide-next {
  margin-top: 5px;
  height: 155px !important;
}

/* 中间的图片 */
.swiper-container .swiper-wrapper .swiper-slide-active {
  height: 165px !important;
  transform: scale(1) !important;
  box-shadow: 0 0 14px 6px #f0f0f0;
}

/deep/ .swiper-pagination-bullet-active {
  background-color: #767676 !important;
}

/deep/ .swiper-pagination-bullet {
  margin: 0px 2px 0px !important;
  width: 5px;
  height: 5px;
}

// /deep/ .swiper-pagination{
//   bottom: -20px;
// }
</style>